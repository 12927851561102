import { getPromise } from '@shared/util/promise'

export const PPT_SUFFIX = ['.ppt', '.pptx']
export const WORD_SUFFIX = ['.doc', '.docx']
export const EXCEL_SUFFIX = ['.xls', '.xlsx']
export const PDF_SUFFIX = ['.pdf']
export const TXT_SUFFIX = ['.txt']
export const ZIP_SUFFIX = ['.zip', '.rar', '.7z', '.bz2']
export const IMG_SUFFIX = ['.png', '.jpeg', '.jpg', '.bmp', '.gif']
export const VIDEO_SUFFIX = ['.mp4', '.avi', '.mov', '.wmv', '.webm', '.ogg', '.ogm', '.ogv', '.flv', '.rmvb', '.mkv']
export const AUDIO_SUFFIX = ['.mp3', '.wav', '.wma', '.flac']
export const HTML5_VIDEO_SUFFIX = ['.mp4', '.webm', '.ogg', '.ogm', '.ogv']
export const HTML5_AUDIO_SUFFIX = ['.mp3', '.wav', '.flac']
export const MODEL_3D_SUFFIX = ['.fbx', '.gltf', '.json', '.obj', '.ply', '.stl']

export const BELONG_MAP = {
  ppt: PPT_SUFFIX,
  word: WORD_SUFFIX,
  excel: EXCEL_SUFFIX,
  pdf: PDF_SUFFIX,
  txt: TXT_SUFFIX,
  zip: ZIP_SUFFIX,
  img: IMG_SUFFIX,
  video: VIDEO_SUFFIX,
  audio: AUDIO_SUFFIX,
}

export const FILE_TYPE = {
  PPT: 'ppt',
  PPTX: 'pptx',
  WORD: 'word',
  EXCEL: 'excel',
  PDF: 'pdf',
  TXT: 'txt',
  ZIP: 'zip',
  IMG: 'img',
  VIDEO: 'video',
  AUDIO: 'audio',
}

export const SUPPORT_OFFICE_TYPES = [FILE_TYPE.PPT, FILE_TYPE.PPTX, FILE_TYPE.WORD, FILE_TYPE.EXCEL]
export const OFFICE_PREVIEW_URL = 'https://view.officeapps.live.com/op/embed.aspx'

export const getBelong = (fileName) => {
  const suffix = `.${fileName?.split('.').pop()}`
  for (const belong in BELONG_MAP) {
    if (BELONG_MAP[belong].includes(suffix.toLowerCase())) {
      return belong
    }
  }
}

export const inType = (fileName = '', fileTypes = '') => {
  const suffix = `.${fileName?.split('.').pop()}`
  return fileTypes.includes(suffix)
}

export const getNameWithoutSuffix = (fileName = '') => {
  if (fileName.includes('.')) {
    return fileName.split('.').slice(0, -1).join('.')
  }
  return fileName
}

export const getFileSuffix = (fileName = '') => {
  return `.${fileName?.split('.').pop()}`
}

export const getFilePreviewInfo = (url) => {
  const { promise, resolve } = getPromise()
  let fileUrl = ''
  let fileText = ''
  let fileType
  try {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const { name, suffix } = getFileName(url)
    fileType = getBelong(`${name}.${suffix}`) || ''
  } catch (ignore) {
    fileType = getBelong(url) || ''
  }
  if (fileType === FILE_TYPE.TXT) {
    const xhr = new XMLHttpRequest()
    xhr.open('get', url, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      if (this.status === 200) {
        const reader = new FileReader()
        reader.onload = function () {
          fileText = this.result
          fileUrl = url
          resolve({
            fileUrl,
            fileText,
          })
        }
        reader.readAsText(this.response)
      }
    }
    xhr.send()
  } else {
    if (SUPPORT_OFFICE_TYPES.includes(fileType)) {
      fileUrl = `${OFFICE_PREVIEW_URL}?src=${encodeURIComponent(url)}`
    } else if (fileType === FILE_TYPE.PDF) {
      fileUrl = url
    }
    resolve({
      fileUrl,
      fileText,
    })
  }
  return promise
}

export const getFileName = (url) => {
  const parsedUrl = new URL(url)
  const fileName = decodeURIComponent(parsedUrl.pathname.split('/').pop() || '')
  const name = getNameWithoutSuffix(fileName) || ''
  const suffix = fileName?.split('.').pop() || ''
  return {
    name,
    suffix,
  }
}
